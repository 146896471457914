<template>
  <b-card>
    <b-card-title style="font-size: xx-large; text-align: center;">
      Service Info
    </b-card-title>
    <b-card-text v-show="serviceData.name">
      <strong>Name: </strong>{{ serviceData.name }}
    </b-card-text>
    <b-card-text v-show="serviceData.description">
      <strong>Description: </strong>{{ serviceData.description }}
    </b-card-text>
    <b-card-text v-show="serviceData.approved">
      <strong>Approved: </strong>{{ serviceData.approved }}
    </b-card-text>
    <b-card-text v-show="serviceData.estimate_money">
      <strong>Estimate Money: </strong>{{ serviceData.estimate_money }}
    </b-card-text>
    <b-card-text v-show="serviceData.location">
      <strong>Location: </strong>{{ serviceData.location }}
    </b-card-text>
    <b-card-text v-show="serviceData.days_require">
      <strong>Days Require: </strong>{{ serviceData.days_require }}
    </b-card-text>

    <diV>
      <b-card-title
        v-show="serviceData.request"
        style="font-size: xx-large;"
        class="py-2"
      >
        Request Info
      </b-card-title>
      <b-card-text v-if="serviceData.request.request_code">
        <strong>Request Code: </strong>{{ serviceData.request.request_code }}
      </b-card-text>
      <b-card-text v-if="serviceData.request.status">
        <strong>Request Status: </strong>{{ serviceData.request.status }}
      </b-card-text>
      <b-card-text v-if="serviceData.request.source">
        <strong>Source: </strong>{{ serviceData.request.source }}
      </b-card-text>
      <b-card-text v-if="serviceData.request.certificate_name">
        <strong>Certificate Name: </strong>{{ serviceData.request.certificate_name.en }}
      </b-card-text>
      <b-card-text v-if="serviceData.request.certificate_name">
        <strong>Certificate Name Arabic: </strong>{{ serviceData.request.certificate_name.ar }}
      </b-card-text>
      <b-card-text v-if="serviceData.request.payment_status">
        <strong>Payment Status: </strong>{{ serviceData.request.payment_status }}
      </b-card-text>
      <b-card-text v-if="serviceData.request.final_price">
        <strong>Final Price: </strong>{{ serviceData.request.final_price }}
      </b-card-text>
      <b-card-text v-if="serviceData.request.location">
        <strong>Location: </strong>{{ serviceData.request.location }}
      </b-card-text>
      <b-card-text v-if="serviceData.request.require_audit">
        <strong>Require Audit: </strong>{{ serviceData.request.require_audit }}
      </b-card-text>
      <b-card-text v-if="serviceData.request.due_date">
        <strong>Due Date: </strong>{{ serviceData.request.due_date }}
      </b-card-text>
    </diV>

    <div>
      <b-card-title
        v-show="serviceData.requester"
        style="font-size: xx-large;"
        class="py-3"
      >
        Requester Info
      </b-card-title>
      <b-card-text v-if="serviceData.requester.name">
        <strong>Name: </strong>{{ serviceData.requester.name }}
      </b-card-text>
      <b-card-text v-if="serviceData.requester.email">
        <strong>Email: </strong>{{ serviceData.requester.email }}
      </b-card-text>
      <b-card-text v-if="serviceData.requester.phone">
        <strong>Phone: </strong>{{ serviceData.requester.phone }}
      </b-card-text>
      <b-card-text v-if="serviceData.requester.description">
        <strong>Description: </strong>{{ serviceData.requester.description }}
      </b-card-text>
      <b-card-text v-if="serviceData.requester.job_description">
        <strong>Job Description: </strong>{{ serviceData.requester.job_description }}
      </b-card-text>
      <b-card-text v-if="serviceData.requester.wallet_balance">
        <strong>Wallet Balance: </strong>{{ serviceData.requester.wallet_balance }}
      </b-card-text>
    </div>

    <div v-show="serviceFile.length > 0">
      <b-card-title
        style="font-size: xx-large;"
        class="pt-4"
      >
        Engineers List
      </b-card-title>
      <div>
        <b-card-text><strong>Files: </strong>
          <ul class="px-2">
            <li
              v-for="file in serviceFile"
              :key="file.id"
            >
              <a
                :href="file.url"
                target="_blank"
              > {{ file.url }} </a>
            </li>
          </ul>
        </b-card-text>
      </div>
    </div>

  </b-card>
</template>

<script>
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      serviceData: {
        name: '',
        description: '',
        approved: '',
        estimate_money: '',
        location: '',
        days_require: '',
        request: {
          id: '',
          request_code: '',
          status: '',
          source: '',
          certificate_name: {
            ar: '',
            en: '',
          },
          payment_status: '',
          final_price: '',
          location: '',
          require_audit: '',
          due_date: '',
        },
        requester: {
          name: '',
          email: '',
          phone: '',
          description: '',
          job_description: '',
          joined_at: '',
          wallet_balance: '',
        },
      },
      serviceFile: [],
    }
  },
  mounted() {
    this.showService()
  },
  methods: {
    showService() {
      if (this.$route.params.id) {
        axios
          .get(`/service/${this.$route.params.id}`)
          .then(res => {
            this.serviceData = decryptData(res.data.payload).data?.service
            this.serviceFile = decryptData(res.data.payload).data?.service.files
          })
      }
    },
  },
}
</script>

<style scoped></style>
